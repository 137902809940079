<template>
  <div style="width: 1200px; margin: auto">
    <div class="titlename">企业动态</div>
    <div class="flex">
      <div class="swiper">
        <el-carousel height="400px" :interval="2000">
          <el-carousel-item v-for="(item, k) in qiye" :key="k">
            <img
              class="imgswiper"
              style="cursor: pointer"
              @click="xiangqing(item.id)"
              :src="item.img"
              alt=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="titleright">
        <div class="biaoti shenglue1">
          {{ qiye[0] && qiye[0].newsTitle }}
        </div>
        <ul style="list-style-type: disc">
          <li
            v-for="(i, k) in qiye"
            :key="k"
            @click="xiangqing(i.id)"
            style="cursor: pointer"
            class="flexsb flexy"
          >
            <div class="shenglue1" style="width: 330px">
              {{ i.newsTitle }}
            </div>
            <div>{{ i.createTime }}</div>
          </li>
        </ul>
      </div>
    </div>
    <img style="margin: 40px 0" src="@/common/image/bann.png" alt="" />
    <!-- 商家资讯 -->
    <div class="titlezhixun flexsb">
      <div>商家资讯</div>
      <div class="flexcont" @click="godetails(2)">更多</div>
    </div>
    <div>
      <list :list="zixum" />
    </div>

    <!-- 商家资讯 -->
    <div class="titlezhixun flexsb">
      <div>会员资讯</div>
      <div class="flexcont" @click="godetails(3)">更多</div>
    </div>
    <div>
      <list :list="huiyuan" />
    </div>
  </div>
</template>
<script>
import list from "@/components/dotai/datalist";
export default {
  name: "dotai",
  components: { list },
  data() {
    return {
      qiye: [], //企业动态
      zixum: [], //商家咨询
      huiyuan: [], //会员咨询
    };
  },
  created() {
    this.getzixun(1);
    this.getzixun(2);
    this.getzixun(3);
  },
  watch: {},
  methods: {
    //   更多跳转
    godetails(e) {
      this.$router.push({ path: `/consultDetails/${e}` });
    }, // 详情跳转
    xiangqing(e) {
      this.$router.push({ path: `/dynamicDetails/${e}` });
    },
    // 资讯动态
    getzixun(e) {
      this.api
        .news({ pageNum: 1, pageSize: 10, type: e })
        .then((res) => {
          switch (e) {
            case 1:
              this.qiye = res.data.rows.reverse();
              break;
            case 2:
              this.zixum = res.data.rows.reverse();
              break;
            case 3:
              this.huiyuan = res.data.rows.reverse();
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.titlezhixun {
  padding-bottom: 15px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 20px;
  div:nth-child(1) {
    padding-left: 9px;
    border-left: 2px solid #981c1c;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #981c1c;
  }
  div:nth-child(2) {
    cursor: pointer;
    width: 48px;
    height: 24px;
    border: 1px solid #eeeeee;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
}

::-webkit-scrollbar {
  width: 1px;
}
.titleright {
  margin-left: 20px;
  width: 460px;

  ul {
    box-sizing: border-box;
    overflow-y: auto;
    height: 352px;
    padding: 10px 10px 10px 0;
  }
  ul li {
    list-style-type: disc;
    margin-bottom: 12px;
  }
  ul li div:nth-child(1) {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  ul li div:nth-child(2) {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
  .biaoti {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #981c1c;
    line-height: 48px;
  }
}

.swiper {
  min-width: 720px;
  max-width: 720px;
  height: 400px;
  .imgswiper {
    width: 100%;
    height: 100%;
  }
}
.titlename {
  padding-left: 9px;
  border-left: 2px solid #981c1c;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #981c1c;
  margin: 19px 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
